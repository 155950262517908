import React, { ReactNode } from "react";
import { styles } from "./styles";

type Props = {
  redHeaderText: string;
  children: ReactNode;
};

export default function ContactInformationBox({
  redHeaderText,
  children,
}: Props) {
  return (
    <styles.ContactInformationBox>
      <styles.RedColorHeaderStyle>{redHeaderText}</styles.RedColorHeaderStyle>
      {children}
    </styles.ContactInformationBox>
  );
}
