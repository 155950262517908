import React from "react";
import { styles } from "./styles";
import searchSvg from "./logo_search.svg";
import { useTranslation } from "react-i18next";

type Props = {
  onChange: Function;
  searchTerm: string;
};

export default function SearchBar({ onChange, searchTerm }: Props) {
  function handleOnChange(event: any) {
    onChange(event.target.value);
  }

  const { t } = useTranslation();

  return (
    <styles.SearchBarContainer>
      <styles.Icon src={searchSvg} />
      <styles.SearchBar
        placeholder={t("search.bar.placeholder")}
        onChange={handleOnChange}
        value={searchTerm}
      />
    </styles.SearchBarContainer>
  );
}
