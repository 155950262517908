import styled from "styled-components";
import { color } from "../../../styles/colors";
import { spacer } from "../../../styles/variables";

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${color.grey100};
  width: 100%;
  border: 1px solid ${color.grey100};
  box-sizing: border-box;
  border-radius: 10px;
  margin-right: ${spacer.xl};
`;

export const styles = {
  MainContainer,
};
