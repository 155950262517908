import React from "react";
import { styles } from "./styles";
import { useTranslation } from "react-i18next";
import ContactInformationBox from "./components/ContactInformationBox/ContactInformationBox";

export default function ContactInformationContainer() {
  const { t } = useTranslation();
  return (
    <styles.ContactInformationContainer>
      <ContactInformationBox
        redHeaderText={t("header.fund.report.subscription.service")}
      >
        <styles.TextBoxZeroGaps>
          Suomen Sijoitustutkimus Oy
        </styles.TextBoxZeroGaps>
        <styles.TextBoxZeroGaps>Puh. 09 6227 8770</styles.TextBoxZeroGaps>
        <styles.LinkStyle>rahastot@sijoitustutkimus.fi</styles.LinkStyle>
      </ContactInformationBox>

      <ContactInformationBox redHeaderText={t("header.data.issues")}>
        <styles.LinkStyle>rahastot@sijoitustutkimus.fi</styles.LinkStyle>
      </ContactInformationBox>

      <ContactInformationBox redHeaderText={t("header.service.provider")}>
        <styles.TextBoxZeroGaps>
          Suomen Sijoitustutkimus Oy
        </styles.TextBoxZeroGaps>
        <styles.TextBoxZeroGaps>Vuorikatu 14 A</styles.TextBoxZeroGaps>
        <styles.TextBoxBiggerGaps>00100 Helsinki</styles.TextBoxBiggerGaps>
        <styles.TextBoxBiggerGaps>09 6227 8770</styles.TextBoxBiggerGaps>
        <styles.TextBoxZeroGaps>
          <styles.LinkStyle>rahastot@sijoitustutkimus.fi</styles.LinkStyle>
        </styles.TextBoxZeroGaps>
        <styles.LinkStyle href="https://www.sijoitustutkimus.fi/">
          sijoitustutkimus.fi
        </styles.LinkStyle>
      </ContactInformationBox>

      <ContactInformationBox redHeaderText={t("header.service.publisher")}>
        <styles.TextBoxZeroGaps>Finanssiala ry</styles.TextBoxZeroGaps>
        <styles.LinkStyle href="https://www.finanssiala.fi/">
          finanssiala.fi
        </styles.LinkStyle>
      </ContactInformationBox>

      <ContactInformationBox redHeaderText={t("header.subscription.service")}>
        <styles.TextBoxZeroGaps>
          Suomen Sijoitustutkimus Oy
        </styles.TextBoxZeroGaps>
        <styles.TextBoxZeroGaps>
          <styles.LinkStyle href="https://www.sijoitustutkimus.fi/">
            www.sijoitustutkimus.fi
          </styles.LinkStyle>
        </styles.TextBoxZeroGaps>
        <styles.TextBoxZeroGaps>Puh. 09 6227 8770</styles.TextBoxZeroGaps>
        <styles.LinkStyle>rahastot@sijoitustutkimus.fi</styles.LinkStyle>
      </ContactInformationBox>
    </styles.ContactInformationContainer>
  );
}
