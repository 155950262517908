import React from "react";
import { styles } from "./styles";

type Prop = {
  emptySearchText: string;
};

export default function EmptySearchResult({ emptySearchText }: Prop) {
  return <styles.MessageContainer>{emptySearchText}</styles.MessageContainer>;
}
