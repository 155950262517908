export const textSize = {
  xs: "0.625rem",
  sm: "0.75rem",
  md: "0.84rem",
  normal: "0.9rem",
  mmd: "1.0rem",
  lg: "1.4rem",
  xl: "2rem",
  xxl: "2.5rem",
  xxxl: "3rem",
};

export const spacer = {
  xxs: "0.1rem",
  xs: "0.2rem",
  sm: "0.5rem",
  md: "0.75rem",
  normal: "1rem",
  mmd: "1.1rem",
  lg: "1.5rem",
  xl: "2rem",
  xl_: "2.5rem",
  xxl: "3rem",
  xxxl: "4.5rem",
  xxxxl: "5.5rem",
};

export const measurments = {
  xxs: "0.1rem",
  xs: "0.2rem",
  sm: "0.5rem",
  md: "0.75rem",
  normal: "1rem",
  mmd: "1.1rem",
  lg: "1.5rem",
  xl: "2rem",
  xxl: "3rem",
  xxxl: "4rem",
  xxxxl: "5.5rem",
};
