import styled from "styled-components";
import Select from "react-select";
import { textSize, measurments } from "../../../../../../styles/variables";

const SelectDropdown = styled(Select)`
  background-color: white;
  width: 100%;
  font-size: ${textSize.mmd};
  color: black;
  font-family: Eina03-Regular;
  .react-select__control {
    height: ${measurments.xxl};
    cursor: pointer;
  }
  .react-select__indicator-separator {
    display: none;
  }
  .react-select__option {
    cursor: pointer;
  }
`;

export const styles = {
  SelectDropdown,
};
