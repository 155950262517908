import styled from "styled-components";
import { textSize, spacer, measurments } from "../../../../styles/variables";

const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: white;
  max-width: 100%;
  min-height: ${measurments.xxxl};
  margin-bottom: ${spacer.md};
  padding: 20px 30px 8px 30px;
  @media only screen and (max-width: 1200px) {
    flex-direction: column;
  }
`;

const FilterHeader = styled.div`
  font-size: ${textSize.md};
  color: black;
  font-family: Eina03-Regular;
  width: 100%;
  margin-right: ${spacer.sm};
  height: ${spacer.mmd};
  padding-bottom: 1px;
`;

const EmptyHeader = styled.div`
  font-size: ${textSize.md};
  color: black;
  font-family: Eina03-Regular;
  width: 100%;
  height: ${spacer.mmd};
  margin-bottom: 1px;
`;

export const styles = {
  SearchContainer,
  FilterHeader,
  EmptyHeader,
};
