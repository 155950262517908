import { makeLastUpdatedDate } from "../lastUpdatedSelectors";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import { RootState } from "../../../reduxStore/store";

export function useSelectLastUpdated() {
  const lastUpdatedStringSelector = useMemo(makeLastUpdatedDate, []);
  const lastUpdatedString = useSelector((state: RootState) =>
    lastUpdatedStringSelector(state)
  );
  return lastUpdatedString;
}
