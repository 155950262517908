import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./reduxStore/store";
import "./App.css";
import FundPrices from "./pages/FundPrices/FundPrices";
import FundMarketNews from "./pages/FundMarketNews/FundMarketNews";
import AboutTheService from "./pages/AboutTheService/AboutTheService";

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path="/">
            <Redirect to="/fundPrices" />
          </Route>
          <Route exact path="/fundPrices" component={FundPrices} />
          <Route exact path="/fundMarketNews" component={FundMarketNews} />
          <Route exact path="/aboutTheService" component={AboutTheService} />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
