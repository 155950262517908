import React, { ReactNode } from "react";
import ContentHeader from "../ContentHeader/ContentHeader";

type Prop = {
  children: ReactNode;
  contentHeaderText: string;
};

export default function ContentContainer({
  children,
  contentHeaderText,
}: Prop) {
  return (
    <>
      <ContentHeader headerText={contentHeaderText} extraText="" />
      {children}
    </>
  );
}
