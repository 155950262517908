import React, { ReactNode } from "react";
import { styles } from "./styles";
import TopHeader from "../misc/TopHeader/TopHeader";

type Props = {
  children: ReactNode;
};

export default function BaseLayout({ children }: Props) {
  return (
    <styles.MainContainer>
      <TopHeader />
      <styles.ContentContainer>{children}</styles.ContentContainer>
    </styles.MainContainer>
  );
}
