import React from "react";
import { styles } from "./styles";
import { INewsItemType } from "../../../../types";
import useLanguage from "../../../../utils/customHook";
import ReactHtmlParser from "react-html-parser";

type Props = {
  newsItemData: INewsItemType | undefined;
};

export default function SelectedItemContainer({ newsItemData }: Props) {
  const { lang } = useLanguage();

  return (
    <>
      {newsItemData && (
        <styles.SelectedItemContainer>
          {newsItemData.title && (
            <styles.BlackColorHeaderStyle>
              {lang.EN ? newsItemData.title.en : newsItemData.title.fi}
            </styles.BlackColorHeaderStyle>
          )}
          <styles.TextBoxBiggerGaps>
            {newsItemData.content?.map(
              (data, index) =>
                data.value !== null && (
                  <styles.SelectedItemText key={index}>
                    <styles.SelectedItemName>
                      {lang.EN ? data.name.en + ":" : data.name.fi + ":"}
                    </styles.SelectedItemName>
                    <styles.SelectedItemValue>
                      {ReactHtmlParser(data.value)}
                    </styles.SelectedItemValue>
                  </styles.SelectedItemText>
                )
            )}
          </styles.TextBoxBiggerGaps>
        </styles.SelectedItemContainer>
      )}
    </>
  );
}
