import React, { useEffect, useState } from "react";
import { styles } from "./styles";
import BaseLayout from "../../components/layouts/BaseLayout";
import SelectionContainer from "./components/SelectionContainer/SelectionContainer";
import FundPricesTable from "./components/Table/FundPricesTable";
import { useDispatch } from "react-redux";
import { getFundPricesTableRows } from "../../utils/api";
import { setUpdatedFundPricesTableRows } from "../../features/fundPrices/fundPricesSlice";
import { useSelectFundPricesList } from "../../features/fundPrices/hooks/useSelectFundPricesList";
import { useAsyncEffect } from "@react-hook/async";
import { IFundPriceTableRow } from "../../types";
import EmptySearchResult from "../../components/misc/EmptySearchResult/EmptySearchResult";
import ContentHeader from "../../components/misc/ContentHeader/ContentHeader";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { useSelectLastUpdated } from "../../features/lastUpdatedDate/hooks/useSelectLastUpdated";
import { setInitialLastUpdatedDate } from "../../features/lastUpdatedDate/lastUpdatedSlice";

export default function FundPrices() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const fundPriceTableData = useSelectFundPricesList();
  const [filteredTable, setFilteredTable] = useState<IFundPriceTableRow[]>();

  const lastUpdated = useSelectLastUpdated();

  useAsyncEffect(async () => {
    if (!fundPriceTableData?.length) {
      const responseTable = await getFundPricesTableRows();
      dispatch(setUpdatedFundPricesTableRows(responseTable.rows));
      dispatch(
        setInitialLastUpdatedDate(
          format(new Date(responseTable.date), "dd.MM.yyyy HH:mm")
        )
      );
    }
  }, [dispatch]);

  useEffect(() => {
    setFilteredTable(fundPriceTableData);
  }, [fundPriceTableData]);

  /*
  console.log("RENDER FundPrices", {
    fundPriceTableData,
    filteredTable,
  });
*/

  return (
    <BaseLayout>
      <styles.MainContainer>
        <ContentHeader
          headerText={t("header.fund.prices")}
          extraText={`${t("fund.prices.last.updated")}: ${lastUpdated}`}
        />
        <SelectionContainer
          fundPriceTableData={fundPriceTableData}
          setFilteredTable={setFilteredTable}
        />
        {filteredTable?.length === 0 ? (
          <EmptySearchResult
            emptySearchText={t("fund.prices.empty.search.result")}
          />
        ) : (
          <FundPricesTable fundPriceTableData={filteredTable} />
        )}
      </styles.MainContainer>
    </BaseLayout>
  );
}
