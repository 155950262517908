import React, { useState, useEffect } from "react";
import { styles } from "./styles";
import BaseLayout from "../../components/layouts/BaseLayout";
import LeftColumn from "../../components/misc/LeftColumn/LeftColumn";
import RightColumn from "../../components/misc/RightColumn/RightColumn";
import SearchBar from "../../components/misc/SearchBar/SearchBar";
import NewsItem from "./components/NewsItem/NewsItem";
import SelectedItemContainer from "./components/SelectedItemContainer/SelectedItemContainer";
import { INewsItemType } from "../../types";
import { useDispatch } from "react-redux";
import { getFundMarketNewsList } from "../../utils/api";
import { setFundMarketInitialList } from "../../features/fundMarketNews/fundMarketNewsSlice";
import { filterNewsItems } from "../../utils/utils";
import { useSelectFundMarketNewsList } from "../../features/fundMarketNews/hooks/useSelectFundMarketNewsList";
import useLanguage from "../../utils/customHook";
import { useTranslation } from "react-i18next";
import Header from "./Header/Header";
import EmptySearchResult from "../../components/misc/EmptySearchResult/EmptySearchResult";

export default function FundMarketNews() {
  const [newsElement, setNewsElement] = useState<INewsItemType>();
  const { t } = useTranslation();
  const { lang } = useLanguage();
  const dispatch = useDispatch();

  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const fundMarketNewsData = useSelectFundMarketNewsList();
  const [newsList, setNewsList] = useState(fundMarketNewsData);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getFundMarketNewsList().then((listElements) => {
      dispatch(setFundMarketInitialList(listElements));
      setLoading(false);
    });
  }, [dispatch]);

  useEffect(() => {
    setNewsList(fundMarketNewsData);
    setNewsElement(fundMarketNewsData[0]);
  }, [fundMarketNewsData]);

  const currentYear = new Date().getFullYear();

  const dateStart = new Date(currentYear, 0, 1);
  const dateEnd = new Date(currentYear + 1, 0, 1);

  const filtered = newsList.filter((item) => {
    return (
      new Date(item.action_date) >= dateStart &&
      new Date(item.action_date) <= dateEnd
    );
  });

  useEffect(() => {
    if (searchTerm) {
      const updatedListElements = filterNewsItems(
        searchTerm,
        fundMarketNewsData,
        lang
      );
      setNewsElement(updatedListElements[0]);
      return setNewsList(updatedListElements);
    } else {
      return setNewsList(fundMarketNewsData);
    }
    // eslint-disable-next-line
  }, [searchTerm]);

  const handleSearch = (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const handleOnClick = (data: INewsItemType) => {
    setNewsElement(data);
  };

  /*
  console.log("RENDER FundPricesTable", {
    fundMarketNewsData,
    newsList,
    searchTerm
  });
  */

  return (
    <BaseLayout>
      <styles.MainContainer>
        <LeftColumn>
          <Header />
          <styles.NewsContainer>
            <SearchBar searchTerm={searchTerm || ""} onChange={handleSearch} />
            {newsList.length !== 0 ? (
              <styles.NewsWrapper>
                {filtered.map((data) => (
                  <NewsItem
                    key={data.id}
                    newsItemData={data}
                    onClick={handleOnClick}
                  />
                ))}
              </styles.NewsWrapper>
            ) : loading ? (
              <styles.NewsWrapper>
                <span>Loading...</span>
              </styles.NewsWrapper>
            ) : (
              <EmptySearchResult
                emptySearchText={t("fund.news.empty.search.result")}
              />
            )}
          </styles.NewsContainer>
        </LeftColumn>
        <RightColumn>
          <SelectedItemContainer newsItemData={newsElement} />
        </RightColumn>
      </styles.MainContainer>
    </BaseLayout>
  );
}
