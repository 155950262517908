import React from "react";
import { styles } from "./styles";
import Logo from "./components/Logo/Logo";
import NavigationBar from "./components/NavigationBar/NavigationBar";
import LanguageSelection from "./components/LanguageSelection/LanguageSelection";

export default function TopHeader() {
  return (
    <styles.TopHeader>
      <styles.LogoWrapper>
        <Logo />
      </styles.LogoWrapper>
      <styles.NavigationBarWrapper>
        <NavigationBar />
      </styles.NavigationBarWrapper>
      <styles.LanguageSelectionWrapper>
        <LanguageSelection />
      </styles.LanguageSelectionWrapper>
    </styles.TopHeader>
  );
}
