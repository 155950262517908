import React from "react";
import { styles } from "./styles";
import Button from "../../../../components/misc/Button/Button";
import { useTranslation } from "react-i18next";
import ContentContainer from "../../../../components/misc/ContentContainer/ContentContainer";

export default function FundReportArchiveContainer() {
  const { t } = useTranslation();
  return (
    <styles.FundReportArchiveContainer>
      <ContentContainer contentHeaderText={t("header.fund.report.archive")}>
        <styles.InfoContainer>
          <styles.SubscriptionContainer>
            <styles.TextBoxZeroGaps>
              {t("fund.report.text")}
            </styles.TextBoxZeroGaps>
          </styles.SubscriptionContainer>
        </styles.InfoContainer>
        <styles.ButtonWrapper>
          <Button buttonText="Siirry rahastoraporttiarkistoon" />
        </styles.ButtonWrapper>
      </ContentContainer>
    </styles.FundReportArchiveContainer>
  );
}
