import { RootState } from "../../reduxStore/store";
import { createSelector } from "reselect";
import { LanguageButtonState } from "./languageButtonSlice";

const languageButtonDomain = (state: RootState): LanguageButtonState => {
  return state?.languageButton;
};

const makeLanguageString = () =>
  createSelector(languageButtonDomain, (subState) => {
    return subState.languageString;
  });

export { makeLanguageString };
