import React, { useEffect, useState } from "react";
import { styles } from "./styles";
import SearchBar from "../../../../components/misc/SearchBar/SearchBar";
import { useTranslation } from "react-i18next";
import SelectDropdown from "./components/SelectDropdown/SelectDropdown";
import FilterContainer from "./components/FilterContainer/FilterContainer";
import { IFundPriceTableRow, ISelectBoxType } from "../../../../types";
import { filterFundPrices } from "../../../../utils/utils";
import useLanguage from "../../../../utils/customHook";

type Props = {
  fundPriceTableData?: IFundPriceTableRow[];
  setFilteredTable: (arr: IFundPriceTableRow[]) => void;
};

export default function SelectionContainer({
  fundPriceTableData = [],
  setFilteredTable,
}: Props) {
  const { t } = useTranslation();
  const { lang } = useLanguage();

  const [selectCompany, setSelectCompany] = useState<ISelectBoxType | null>(
    null
  );
  const [selectClass, setSelectClass] = useState<ISelectBoxType | null>(null);
  const [selectSearchTerm, setSearchTerm] = useState<string | null>(null);

  const companyItems: ISelectBoxType[] = [];
  const classItems: ISelectBoxType[] = [];

  fundPriceTableData.forEach((element) => {
    let newElementCompany = {
      value: lang.EN ? element.company.en : element.company.fi,
      label: lang.EN ? element.company.en : element.company.fi,
    };
    let newElementClass = {
      value: lang.EN ? element.class.en : element.class.fi,
      label: lang.EN ? element.class.en : element.class.fi,
    };
    companyItems.push(newElementCompany);
    classItems.push(newElementClass);
  });

  const uniqueCompanyItems = Array.from(
    new Map(companyItems.map((item) => [item["value"], item])).values()
  ).sort((company1, company2) => {
    return (company1.value ? company1.value : "") >
      (company2.value ? company2.value : "")
      ? 1
      : -1;
  });

  const uniqueClassItems = Array.from(
    new Map(classItems.map((item) => [item["value"], item])).values()
  ).sort((class1, class2) => {
    return (class1.value ? class1.value : "") >
      (class2.value ? class2.value : "")
      ? 1
      : -1;
  });

  useEffect(() => {
    if (selectSearchTerm) {
      setFilteredTable(
        filterFundPrices(selectSearchTerm, fundPriceTableData, lang)
      );
    } else if (selectCompany) {
      const updatedCompanyElements = fundPriceTableData.filter((priceItem) => {
        return lang.EN
          ? priceItem.company.en.includes(
              selectCompany?.value === null ? "" : selectCompany?.value
            )
          : priceItem.company.fi.includes(
              selectCompany?.value === null ? "" : selectCompany?.value
            );
      });
      setFilteredTable(updatedCompanyElements);
    } else if (selectClass) {
      const updatedClassElements = fundPriceTableData.filter((priceItem) => {
        return lang.EN
          ? priceItem.class.en.includes(
              selectClass?.value === null ? "" : selectClass?.value
            )
          : priceItem.class.fi.includes(
              selectClass?.value === null ? "" : selectClass?.value
            );
      });
      setFilteredTable(updatedClassElements);
    } else {
      setFilteredTable(fundPriceTableData);
    }
    // eslint-disable-next-line
  }, [
    selectCompany,
    selectClass,
    selectSearchTerm,
    fundPriceTableData,
    setFilteredTable,
  ]);

  const handleSearchTerm = (term: string) => {
    setSearchTerm(term);
    setSelectCompany(null);
    setSelectClass(null);
  };

  const handleSelectCompany = (selectTermCompany: ISelectBoxType) => {
    setSearchTerm(null);
    setSelectCompany(selectTermCompany);
    setSelectClass(null);
  };

  const handleSelectClass = (selectTermClass: ISelectBoxType) => {
    setSearchTerm(null);
    setSelectCompany(null);
    setSelectClass(selectTermClass);
  };

  return (
    <styles.SearchContainer>
      <FilterContainer>
        <styles.FilterHeader>
          {t("select.dropdown.header.company")}
        </styles.FilterHeader>
        <SelectDropdown
          onChange={handleSelectCompany}
          selectTerm={selectCompany}
          options={uniqueCompanyItems}
          placeholderText={t("select.dropdown.placeholder.company")}
          clearable={true}
        />
      </FilterContainer>
      <FilterContainer>
        <styles.FilterHeader>
          {t("select.dropdown.header.class")}
        </styles.FilterHeader>
        <SelectDropdown
          onChange={handleSelectClass}
          selectTerm={selectClass}
          options={uniqueClassItems}
          placeholderText={t("select.dropdown.placeholder.class")}
          clearable={true}
        />
      </FilterContainer>
      <FilterContainer>
        <styles.EmptyHeader>{""}</styles.EmptyHeader>
        <SearchBar
          searchTerm={selectSearchTerm || ""}
          onChange={handleSearchTerm}
        />
      </FilterContainer>
    </styles.SearchContainer>
  );
}
