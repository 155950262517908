import styled from "styled-components";
import { color } from "../../styles/colors";
import { spacer } from "../../styles/variables";

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: ${color.grey100};
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: ${spacer.xl};
  margin-right: ${spacer.xl};
  margin-left: ${spacer.xl};
  height: auto;
  max-width: 100%;
`;

export const styles = {
  MainContainer,
  ContentContainer,
};
