import styled from "styled-components";
import { color } from "../../../../styles/colors";
import { textSize, spacer, measurments } from "../../../../styles/variables";

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding: ${spacer.lg} ${spacer.lg} 0 ${spacer.lg};
  background-color: white;
`;

const SubscriptionContainer = styled.div`
  max-width: 100%;
  font-family: Eina03-Regular;
  font-size: ${textSize.md};
  color: black;
  margin-bottom: 20px;
`;

const TextBoxZeroGaps = styled.div`
  max-width: 100%;
  padding-bottom: 0px;
`;

const FundReportArchiveContainer = styled.div`
  display: flex;
  flex-direction: column;
  display: flex;
  max-width: 100%;
  background-color: white;
  margin-top: ${spacer.lg};
  border: 1px solid ${color.grey500};
  box-sizing: border-box;
  border-radius: 10px;
  @media only screen and (max-width: 1200px) {
    margin-bottom: 20px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  height: ${measurments.xxxl};
  max-width: 100%;
  margin-bottom: ${spacer.lg};
  margin-left: ${spacer.lg};
  margin-right: 100px;
`;

export const styles = {
  InfoContainer,
  SubscriptionContainer,
  TextBoxZeroGaps,
  FundReportArchiveContainer,
  ButtonWrapper,
};
