import styled from "styled-components";
import { color } from "../../../../../../styles/colors";
import { textSize } from "../../../../../../styles/variables";

const ContactInformationBox = styled.div`
  max-width: 100%;
  font-family: Eina03-Regular;
  font-size: ${textSize.md};
  color: black;
  margin-bottom: 20px;
`;

const RedColorHeaderStyle = styled.p`
  font-family: Eina03-BOLD;
  font-size: ${textSize.normal};
  color: ${color.red300};
`;

export const styles = {
  ContactInformationBox,
  RedColorHeaderStyle,
};
