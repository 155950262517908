import styled from "styled-components";
import { textSize, spacer, measurments } from "../../../../styles/variables";

const SelectedItemContainer = styled.div`
  align-items: center;
  font-family: Eina03-Regular;
  font-size: ${textSize.mmd};
  color: black;
  width: 100%;
  box-sizing: border-box;
  padding-right: ${spacer.xl};
  padding-left: ${spacer.xl};
`;

const BlackColorHeaderStyle = styled.p`
  font-family: Eina03-bold;
  font-size: ${textSize.lg};
  color: black;
`;
const TextBoxBiggerGaps = styled.div`
  max-width: 100%;
  padding-bottom: 20px;
  justify-content: center;
  font-size: ${textSize.md};
`;

const SelectedItemText = styled.div`
  flex-direction: row;
  font-family: Eina03-Regular;
  font-size: ${textSize.normal};
  line-height: ${measurments.normal};
  display: flex;
  align-items: center;
  color: black;
  gap: 5px;
`;

const SelectedItemName = styled.div`
  display: flex;
  align-items: center;
  font-family: Eina03-Bold;
`;

const SelectedItemValue = styled.div`
  align-items: center;
`;

export const styles = {
  SelectedItemContainer,
  BlackColorHeaderStyle,
  TextBoxBiggerGaps,
  SelectedItemText,
  SelectedItemName,
  SelectedItemValue,
};
