import styled from "styled-components";
import { color } from "../../../styles/colors";

const MainContainer = styled.div`
  flex: 3;
  background-color: ${color.white};
  max-width: 100%;
  border: 1px solid ${color.grey100};
  box-sizing: border-box;
  border-radius: 10px;
`;

export const styles = {
  MainContainer,
};
