import React, { useEffect, useState } from "react";
import { styles } from "./styles";
import { useTranslation } from "react-i18next";
import { IFundPriceTableRow } from "../../../../types";
import { formatDate, sort } from "../../../../utils/utils";
import downArrowSvg from "./down-arrow.svg";
import useLanguage from "../../../../utils/customHook";

type Props = {
  fundPriceTableData?: IFundPriceTableRow[];
};

type ColumnType =
  | "table.header.share.class"
  | "table.header.date"
  | "table.header.price"
  | "table.header.currency"
  | "table.header.year.low"
  | "table.header.year.high";

type SortColumn = {
  columnType: ColumnType;
  sortDirection: boolean;
};

const initialSortDirection = true;

const mapColumnNameAndKey = (lang: { EN: boolean; FI: boolean }) => {
  let mapColumnNameAndKey = new Map<ColumnType, any>();
  mapColumnNameAndKey.set(
    "table.header.share.class",
    lang.EN ? "companyNameEn" : "companyNameFi"
  );
  mapColumnNameAndKey.set("table.header.date", "closePriceDate");
  mapColumnNameAndKey.set("table.header.price", "closePrice");
  mapColumnNameAndKey.set("table.header.currency", "currency");
  mapColumnNameAndKey.set("table.header.year.low", "lowestValueYTD");
  mapColumnNameAndKey.set("table.header.year.high", "highestValueYTD");

  return mapColumnNameAndKey;
};

export default function FundPricesTable({ fundPriceTableData }: Props) {
  const { lang } = useLanguage();
  const { t } = useTranslation();
  const [sortColumn, setSortColumn] = useState<SortColumn>();
  const [sortedArray, setSortedArray] = useState<IFundPriceTableRow[]>();

  const map = mapColumnNameAndKey(lang);

  useEffect(() => {
    handleClickSort("table.header.share.class");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!fundPriceTableData) return undefined;
    const sorted = sort(
      map.get(sortColumn ? sortColumn.columnType : "table.header.share.class"),
      [...fundPriceTableData]
    );
    const inCorrectOrder = (sortColumn ? sortColumn.sortDirection : true)
      ? sorted
      : sorted.reverse();

    setSortedArray(inCorrectOrder);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortColumn, fundPriceTableData]);

  function handleClickSort(columnType: ColumnType) {
    if (sortColumn?.columnType === columnType) {
      setSortColumn({
        columnType: columnType,
        sortDirection: !sortColumn.sortDirection,
      });
    } else {
      setSortColumn({
        columnType: columnType,
        sortDirection: initialSortDirection,
      });
    }
  }

  return (
    <styles.TableContainer>
      <styles.CustomFundPricesTable>
        <styles.THead>
          <styles.TableRow>
            {[
              "table.header.share.class",
              "table.header.date",
              "table.header.price",
              "table.header.currency",
              "table.header.year.low",
              "table.header.year.high",
            ].map((name) => {
              return (
                <styles.TableHeader
                  key={name}
                  onClick={() => {
                    handleClickSort(name as ColumnType);
                  }}
                >
                  {t(name).toUpperCase()}
                  <styles.SpanForArrow>
                    {sortColumn?.columnType === name ? (
                      sortColumn?.sortDirection ? (
                        <styles.Icon src={downArrowSvg} className="acsending" />
                      ) : (
                        <styles.Icon
                          src={downArrowSvg}
                          className="descending"
                        />
                      )
                    ) : (
                      " "
                    )}
                  </styles.SpanForArrow>
                </styles.TableHeader>
              );
            })}
          </styles.TableRow>
        </styles.THead>
        <styles.CustomFundPricesTableBody>
          {sortedArray ? (
            sortedArray.map((data) => (
              <styles.TableRow key={data.id}>
                <styles.TableDataCell>
                  {lang.EN ? data.companyNameEn : data.companyNameFi}
                </styles.TableDataCell>
                <styles.TableDataCell>
                  {formatDate(data.closePriceDate as string)}
                </styles.TableDataCell>
                <styles.TableDataCell>
                  {Number(data.closePrice).toFixed(2)}
                </styles.TableDataCell>
                <styles.TableDataCell>{data.currency}</styles.TableDataCell>
                <styles.TableDataCell>
                  {Number(data.lowestValueYTD).toFixed(2)}
                </styles.TableDataCell>
                <styles.TableDataCell>
                  {Number(data.highestValueYTD).toFixed(2)}
                </styles.TableDataCell>
              </styles.TableRow>
            ))
          ) : (
            <styles.TableRow>
              <styles.TableDataCell>
                <span>Loading...</span>
              </styles.TableDataCell>
            </styles.TableRow>
          )}
        </styles.CustomFundPricesTableBody>
      </styles.CustomFundPricesTable>
    </styles.TableContainer>
  );
}
