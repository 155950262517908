import styled from "styled-components";
import { color } from "../../../../styles/colors";
import { textSize, measurments } from "../../../../styles/variables";

const NewsItem = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  border-bottom: 1px solid ${color.grey200};
  heigh: 100px;
  padding-top: 16px;
  padding-bottom: 16px;
  &:hover {
    background-color: ${color.grey500};
  }
`;
const NewsItemActionType = styled.div`
  font-family: Eina03-Bold;
  font-size: ${textSize.normal};
  line-height: ${measurments.normal};
  display: flex;
  align-items: center;
  color: ${color.red300};
  padding-bottom: 10px;
`;
const NewsItemText = styled.div`
  flex-direction: row;
  flex-wrap: wrap;
  font-family: Eina03-Regular;
  font-size: ${textSize.md};
  line-height: ${measurments.normal};
  display: flex;
  align-items: center;
  color: black;
  gap: 5px;
`;

const NewsItemName = styled.div`
  display: flex;
  align-items: center;
`;

const NewsItemValue = styled.div`
  display: flex;
  align-items: center;
`;

const NewsItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  cursor: pointer;
`;

export const styles = {
  NewsItem,
  NewsItemActionType,
  NewsItemText,
  NewsItemWrapper,
  NewsItemName,
  NewsItemValue,
};
