import React from "react";
import { styles } from "./styles";
import { INewsItemType } from "../../../../types";
import useLanguage from "../../../../utils/customHook";
import { noticeToShareHolders } from "../../../../utils/stringsConst";

type Props = {
  newsItemData: INewsItemType;
  onClick: Function;
};

export default function NewsItem({ newsItemData, onClick }: Props) {
  const { lang } = useLanguage();

  return (
    <styles.NewsItemWrapper onClick={() => onClick(newsItemData)}>
      <styles.NewsItem>
        {newsItemData.title && (
          <styles.NewsItemActionType>
            {lang.EN ? newsItemData.title.en : newsItemData.title.fi}
          </styles.NewsItemActionType>
        )}
        {newsItemData.content
          ?.slice(0, newsItemData.action_type === noticeToShareHolders ? 2 : 4)
          .map(
            (data, index) =>
              data.value && (
                <styles.NewsItemText key={index}>
                  <styles.NewsItemName>
                    {lang.EN ? data.name.en + ": " : data.name.fi + ": "}
                  </styles.NewsItemName>

                  <styles.NewsItemValue>{data.value}</styles.NewsItemValue>
                </styles.NewsItemText>
              )
          )}
      </styles.NewsItem>
    </styles.NewsItemWrapper>
  );
}
