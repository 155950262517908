import styled from "styled-components";
import { color } from "../../../styles/colors";
import { spacer, textSize } from "../../../styles/variables";

const MessageContainer = styled.div`
  display: flex;
  background-color: ${color.grey100};
  border: 1px solid ${color.grey200};
  max-width: 100%;
  height: 260px;
  overflow: auto;
  margin: ${spacer.md} ${spacer.xl} ${spacer.xl} ${spacer.xl};
  justify-content: center;
  align-items: center;
  font-family: Eina03-Regular;
  font-size: ${textSize.mmd};
  color: ${color.grey400};
`;

export const styles = {
  MessageContainer,
};
