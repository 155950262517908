import { RootState } from "../../reduxStore/store";
import { createSelector } from "reselect";
import { LastUpdatedState } from "./lastUpdatedSlice";

const lastUpdatedDomain = (state: RootState): LastUpdatedState => {
  return state?.lastUpdatedDate;
};

const makeLastUpdatedDate = () =>
  createSelector(lastUpdatedDomain, (subState) => {
    return subState.lastUpdatedString;
  });

export { makeLastUpdatedDate };
