import styled from "styled-components";
import { color } from "../../styles/colors";
import { spacer } from "../../styles/variables";

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${color.grey500};
  width: 100%;
  @media only screen and (max-width: 1200px) {
    flex-direction: column-reverse;
  }
`;

const NewsContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding-right: ${spacer.lg};
  padding-left: ${spacer.lg};
  padding-top: ${spacer.lg};
  background-color: white;
`;
const NewsWrapper = styled.div`
  overflow: auto;
  height: 78vh;
  margin-top: ${spacer.normal};
`;

const NewsItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

export const styles = {
  MainContainer,
  NewsContainer,
  NewsItemWrapper,
  NewsWrapper,
};
