import styled from "styled-components";
import { color } from "../../../../../styles/colors";
import { textSize, spacer } from "../../../../../styles/variables";

const LanguageButton = styled.button`
  background-color: white;
  color: black;
  font-size: ${textSize.normal};
  font-family: Eina03-Bold;
  cursor: pointer;
  border: none;
  &:hover {
    color: ${color.red400};
  }
  &:focus {
    color: ${color.red400};
  }

  &.activeButton {
    color: ${color.red400};
  }
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  float: right;
  padding-right: ${spacer.xl_};
`;

export const styles = {
  MainContainer,
  LanguageButton,
};
