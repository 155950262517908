import styled from "styled-components";
import { textSize, measurments, spacer } from "../../../../styles/variables";
import { color } from "../../../../styles/colors";

const BlackColorHeaderStyle = styled.p`
  font-family: Eina03-BOLD;
  font-size: ${textSize.lg};
  color: black;
`;

const TextContainer = styled.div`
  text-align: left;
  font-family: Eina03-Regular;
  line-height: ${measurments.xl};
  font-size: ${textSize.mmd};
  color: black;
  max-width: 100%;
  padding-right: ${spacer.xl};
  padding-left: ${spacer.xl};
  background-color: white;
`;

const TextBoxBiggerGaps = styled.div`
  max-width: 100%;
  padding-bottom: 20px;
  justify-content: center;
`;

const LinkStyle = styled.a.attrs({
  target: "_blank",
})`
  text-decoration-line: underline;
  color: ${color.red300};
`;

export const styles = {
  BlackColorHeaderStyle,
  TextContainer,
  TextBoxBiggerGaps,
  LinkStyle,
};
