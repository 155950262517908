import React from "react";
import { styles } from "./styles";
import svg from "./logo.svg";

export default function Logo() {
  return (
    <styles.MainContainer>
      <styles.SvgIconContainer src={svg} />
    </styles.MainContainer>
  );
}
