import React from "react";
import { styles } from "./styles";
import BaseLayout from "../../components/layouts/BaseLayout";
import LeftColumn from "../../components/misc/LeftColumn/LeftColumn";
import RightColumn from "../../components/misc/RightColumn/RightColumn";
import { useTranslation } from "react-i18next";
import ContactInformationContainer from "./components/ContactInformationContainer/ContactInformationContainer";
import FundReportArchiveContainer from "./components/FundReportArchiveContainer/FundReportArchiveContainer";
import TextContainer from "./components/TextContainer/TextContainer";
import ContentContainer from "../../components/misc/ContentContainer/ContentContainer";

export default function AboutTheService() {
  const { t } = useTranslation();

  return (
    <BaseLayout>
      <styles.MainContainer>
        <LeftColumn>
          <ContentContainer contentHeaderText={t("header.contact.information")}>
            <ContactInformationContainer />
            <FundReportArchiveContainer />
          </ContentContainer>
        </LeftColumn>
        <RightColumn>
          <TextContainer />
        </RightColumn>
      </styles.MainContainer>
    </BaseLayout>
  );
}
