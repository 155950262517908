import styled from "styled-components";
import { color } from "../../../styles/colors";
import { textSize, measurments, spacer } from "../../../styles/variables";

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: white;
  border-bottom: 1px solid ${color.grey200};
  width: 100%;
  height: ${measurments.xxxl};
`;

const Heading = styled.div`
  flex: 3;
  font-size: ${textSize.normal};
  font-family: Eina03-Bold;
  align-self: center;
  width: 100%;
  height: ${measurments.normal};
  padding-left: ${spacer.lg};
`;

const ExtraText = styled.div`
  flex: 1;
  font-size: ${textSize.normal};
  font-family: Eina03-Bold;
  align-self: center;
  width: 100%;
  height: ${measurments.normal};
  padding-right: ${spacer.lg};
  text-align: right;
`;

export const styles = {
  HeaderContainer,
  Heading,
  ExtraText,
};
