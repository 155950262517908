import styled from "styled-components";
import { color } from "../../../styles/colors";
import { measurments, spacer } from "../../../styles/variables";

const TopHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: ${measurments.xxxxl};
  background-color: white;
  border: 1px solid ${color.grey200};
  box-sizing: border-box;
  width: 100%;
  top: 0px;
  left: 0px;
`;

const LogoWrapper = styled.div`
  align-self: center;
  flex: 1;
  width: 100%;
  height: auto;
  padding-left: ${spacer.xl_};
`;

const NavigationBarWrapper = styled.div`
  align-self: center;
  flex: 2;
  width: 100%;
`;

const LanguageSelectionWrapper = styled.div`
  align-self: center;
  flex: 1;
  width: 100%;
`;

export const styles = {
  TopHeader,
  LogoWrapper,
  NavigationBarWrapper,
  LanguageSelectionWrapper,
};
