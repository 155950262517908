import { makeLanguageString } from "../languageButtonSelectors";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import { RootState } from "../../../reduxStore/store";

export function useSelectLanguageButtonString() {
  const languageStringSelector = useMemo(makeLanguageString, []);
  const languageString = useSelector((state: RootState) =>
    languageStringSelector(state)
  );
  return languageString;
}
