import React, { useState } from "react";
import { styles } from "./styles";
import arrowSvg from "./logo-arrow.svg";

import { useTranslation } from "react-i18next";

export default function NavigationBar() {
  const { t } = useTranslation();
  const [menuVisible, setMenuVisible] = useState(false);
  return (
    <styles.NavigationBarWrapper>
      <styles.IconWrapper onClick={() => setMenuVisible(!menuVisible)}>
        <styles.Icon src={arrowSvg} />
      </styles.IconWrapper>
      <styles.NavigationBar className={menuVisible ? "visible" : ""}>
        <styles.ListElement>
          <styles.ListElementLink to="/fundPrices" activeClassName="current">
            {t("navigation.bar.fund.prices")}
          </styles.ListElementLink>
        </styles.ListElement>
        <styles.ListElement>
          <styles.ListElementLink
            to="/fundMarketNews"
            activeClassName="current"
          >
            {t("navigation.bar.fund.market.news")}
          </styles.ListElementLink>
        </styles.ListElement>
        <styles.ListElement>
          <styles.ListElementLink
            to="/aboutTheService"
            activeClassName="current"
          >
            {t("navigation.bar.about.service")}
          </styles.ListElementLink>
        </styles.ListElement>
      </styles.NavigationBar>
    </styles.NavigationBarWrapper>
  );
}
