import styled from "styled-components";
import { color } from "../../../../styles/colors";
import { spacer } from "../../../../styles/variables";

const ContactInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding: ${spacer.lg} ${spacer.lg} 0 ${spacer.lg};
  background-color: white;
`;

const LinkStyle = styled.a.attrs({
  target: "_blank",
})`
  text-decoration-line: underline;
  color: ${color.red300};
`;

const TextBoxBiggerGaps = styled.div`
  max-width: 100%;
  padding-bottom: 20px;
  justify-content: center;
`;

const TextBoxZeroGaps = styled.div`
  max-width: 100%;
  padding-bottom: 0px;
`;

export const styles = {
  ContactInformationContainer,
  LinkStyle,
  TextBoxBiggerGaps,
  TextBoxZeroGaps,
};
