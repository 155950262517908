import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IFundPriceTableRow } from "../../types";

export type FundPriceState = {
  fundPricesTableRows: IFundPriceTableRow[];
};

// Define the initial state using that type
const initialState: FundPriceState = {
  fundPricesTableRows: [],
};

export const fundPricesSlice = createSlice({
  name: "fundPrices",
  initialState: initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setUpdatedFundPricesTableRows: (
      state,
      action: PayloadAction<IFundPriceTableRow[]>
    ) => {
      state.fundPricesTableRows = action.payload;
    },
  },
});

export const { setUpdatedFundPricesTableRows } = fundPricesSlice.actions;

export default fundPricesSlice.reducer;
