import styled from "styled-components";
import { color } from "../../../styles/colors";
import { textSize, spacer } from "../../../styles/variables";

const Button = styled.a.attrs({
  target: "_blank",
})`
  background: ${color.red300};
  border-radius: ${spacer.xs};
  width: 100%;
  height: auto;
  font-family: Eina03-Bold;
  font-size: ${textSize.normal};
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  border: none;
  cursor: pointer;
  padding: 20px 5px;
  text-decoration: none;
`;

export const styles = {
  Button,
};
