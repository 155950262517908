import React from "react";
import { styles } from "./styles";
import { useTranslation } from "react-i18next";

export default function TextContainer() {
  const { t } = useTranslation();
  return (
    <styles.TextContainer>
      <styles.BlackColorHeaderStyle>
        {t("header.about.service")}
      </styles.BlackColorHeaderStyle>
      <styles.TextBoxBiggerGaps>
        {t("about.service.text.part1")}
      </styles.TextBoxBiggerGaps>
      <styles.TextBoxBiggerGaps>
        {t("about.service.text.part2")}
      </styles.TextBoxBiggerGaps>
      <styles.TextBoxBiggerGaps>
        {t("about.service.text.part3")}
      </styles.TextBoxBiggerGaps>
      {t("about.service.text.part4")}{" "}
      <styles.LinkStyle href="https://www.sijoitustutkimus.fi/">
        {" "}
        {t("about.service.text.link1")}
      </styles.LinkStyle>{" "}
      {t("about.service.text.part5")}{" "}
      <styles.LinkStyle href="https://www.finanssiala.fi/">
        {t("about.service.text.link2")}
      </styles.LinkStyle>{" "}
      {t("about.service.text.part6")}
    </styles.TextContainer>
  );
}
