import styled from "styled-components";

const MainContainer = styled.div`
  width: 72%;
`;

const SvgIconContainer = styled.img`
  max-width: 100%;
  height: auto;
`;

export const styles = {
  MainContainer,
  SvgIconContainer,
};
