import { makeFundMarketNewsList } from "../fundMarketNewsSelectors";
import { useSelector } from "react-redux";
import { createStructuredSelector } from "reselect";
import { RootState } from "../../../reduxStore/store";
import { INewsItemType } from "../../../types";

const stateSelector = createStructuredSelector<
  RootState,
  { fundMarketNewsList: INewsItemType[] }
>({
  fundMarketNewsList: makeFundMarketNewsList(),
});

export function useSelectFundMarketNewsList() {
  const { fundMarketNewsList } = useSelector(stateSelector);
  return fundMarketNewsList;
}
