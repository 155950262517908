import { IFundPriceTableRow, INewsItemType } from "../types";
import { format } from "date-fns";

export const filterFundPrices = (
  searchTerm: string,
  fundPricesList: IFundPriceTableRow[],
  lang: { EN: boolean; FI: boolean }
) =>
  fundPricesList.filter((priceItem) => {
    return (lang.EN ? priceItem.companyNameEn : priceItem.companyNameFi)
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
  });

export const filterNewsItems = (
  searchTerm: string,
  newsList: INewsItemType[],
  language: { EN: boolean; FI: boolean }
) =>
  newsList.filter(({ title, content }) => {
    return (
      (language.EN
        ? title.en === null
          ? ""
          : title.en
        : title.fi === null
        ? ""
        : title.fi
      )
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      content[0].value?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      content[1].value?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      content[2].value?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      content[3].value?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

export const sort = (
  sortType: keyof IFundPriceTableRow,
  fundUnits: IFundPriceTableRow[]
) =>
  fundUnits.sort((a, b) => {
    if (
      sortType === "closePrice" ||
      sortType === "lowestValueYTD" ||
      sortType === "highestValueYTD"
    ) {
      return Number(a[sortType]) > Number(b[sortType]) ? 1 : -1;
    } else if (
      sortType === "companyNameEn" ||
      sortType === "companyNameFi" ||
      sortType === "currency"
    ) {
      return a[sortType].trim() > b[sortType].trim() ? 1 : -1;
    } else
      return getDate(a[sortType] as string) > getDate(b[sortType] as string)
        ? 1
        : -1;
  });

export const formatDate = (date: string) => {
  const year = Number(date.substring(0, 4));
  const month = Number(date.substring(4, 6));
  const day = Number(date.substring(6, 8));
  const newDate = format(new Date(year, month - 1, day), "dd.MM.yyyy");
  return newDate;
};

export const getDate = (date: string) => {
  const year = Number(date?.substring(0, 4));
  const month = Number(date?.substring(4, 6));
  const day = Number(date?.substring(6, 8));
  return new Date(year, month - 1, day);
};
