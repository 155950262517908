import { RootState } from "../../reduxStore/store";
import { createSelector } from "reselect";
import { FundPriceState } from "./fundPricesSlice";
import { IFundPriceTableRow } from "../../types";
import { v4 as uuidv4 } from "uuid";

const fundPricesDomain = (state: RootState): FundPriceState => {
  return state?.fundPrices;
};

const setId = (data: IFundPriceTableRow[] | undefined) => {
  return data?.map((item) => ({
    ...item,
    id: uuidv4(),
  }));
};

const makeSelectFundPricesTableRows = () =>
  createSelector(fundPricesDomain, (subState) => {
    return setId(subState.fundPricesTableRows)!.filter(
      (fundPrice) => fundPrice.closePriceDate !== 0
    );
  });

export { makeSelectFundPricesTableRows };
