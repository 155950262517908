import { makeSelectFundPricesTableRows } from "../fundPricesSelectors";
import { useSelector } from "react-redux";
import { createStructuredSelector } from "reselect";
import { RootState } from "../../../reduxStore/store";
import { IFundPriceTableRow } from "../../../types";

const stateSelector = createStructuredSelector<
  RootState,
  { fundPricesTableRows: IFundPriceTableRow[] }
>({
  fundPricesTableRows: makeSelectFundPricesTableRows(),
});

export function useSelectFundPricesList() {
  const { fundPricesTableRows } = useSelector(stateSelector);
  return fundPricesTableRows;
}
