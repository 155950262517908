const black = "#363841";
const grey100 = "#FBFBFC";
const grey200 = "#E6E7EB";
const grey300 = "#C8CAD2";
const grey400 = "#878B9D";
const grey500 = "#FBFBFC";
const grey600 = "#E8E8E8";

const red100 = "#F2C0D6";
const red200 = "#E481AE";
const red300 = "#A7235E";
const red400 = "#8E0B45";
const red500 = "#6C0935";

const error100 = "#F4E2EA";
const error300 = "#A01953";

const white = "#FFFFFF";

export const color = {
  black,
  grey100,
  grey200,
  grey300,
  grey400,
  grey500,
  grey600,
  red100,
  red200,
  red300,
  red400,
  red500,
  error100,
  error300,
  white,
};
