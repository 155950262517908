import { RootState } from "../../reduxStore/store";
import { createSelector } from "reselect";
import { FundMarketNewsState } from "./fundMarketNewsSlice";
import { INewsItemType } from "../../types";
import { v4 as uuidv4 } from "uuid";

const fundMarketNewsDomain = (state: RootState): FundMarketNewsState => {
  return state?.fundMarketNews;
};
const setId = (data: INewsItemType[]) => {
  return data?.map((item) => ({
    ...item,
    id: uuidv4(),
  }));
};

const makeFundMarketNewsList = () =>
  createSelector(fundMarketNewsDomain, (subState) => {
    return [...setId(subState.fundMarketNewsList)]
      .filter((item) => item.action_type !== "")
      .sort((item1, item2) => {
        return new Date(item1.action_date) > new Date(item2.action_date)
          ? -1
          : 1;
      });
  });

export { makeFundMarketNewsList };
