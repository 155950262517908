import React from "react";
import { styles } from "./styles";
import downloadSvg from "./downloadIcon.svg";
import { useTranslation } from "react-i18next";
import exportReport from "../../../export/excel";
import { useSelectFundMarketNewsList } from "../../../features/fundMarketNews/hooks/useSelectFundMarketNewsList";
import useLanguage from "../../../utils/customHook";

export default function Header() {
  const { t } = useTranslation();
  const { lang } = useLanguage();
  const fundActionsAndNewsData = useSelectFundMarketNewsList();
  return (
    <styles.HeaderContainer>
      <styles.Heading>{t("header.fund.market.news")}</styles.Heading>
      <styles.ButtonWrapper>
        <styles.ButtonWithIcon
          onClick={() =>
            exportReport(fundActionsAndNewsData, lang.EN ? "en" : "fi")
          }
        >
          <styles.Icon src={downloadSvg} />
        </styles.ButtonWithIcon>
      </styles.ButtonWrapper>
    </styles.HeaderContainer>
  );
}
