import React from "react";
import { styles } from "./styles";

type props = {
  buttonText: string;
};

export default function Button({ buttonText }: props) {
  return (
    <styles.Button href="https://www.sijoitustutkimus.fi/palvelut/rahastoyhtioille/rahastojen-tietopalvelut/rahastoraportti/rahastoraporttiarkisto/">
      {buttonText}
    </styles.Button>
  );
}
