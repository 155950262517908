import styled from "styled-components";
import { color } from "../../../styles/colors";
import { textSize, spacer, measurments } from "../../../styles/variables";

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: white;
  border-bottom: 1px solid ${color.grey200};
  width: 100%;
  height: ${measurments.xxxl};
`;
const Heading = styled.div`
  flex: 1;
  font-size: ${textSize.normal};
  font-family: Eina03-Bold;
  align-self: center;
  width: 100%;
  height: ${spacer.normal};
  padding-left: ${spacer.lg};
`;
const ButtonWrapper = styled.div`
  flex: 1;
  align-self: center;
  width: 100%;
`;

const ButtonWithIcon = styled.button`
  height: ${measurments.xl};
  max-width: ${measurments.xl};
  background: white;
  border: none;
  cursor: pointer;
  margin-right: ${spacer.lg};
  float: right;
`;

const Icon = styled.img`
  padding: 0px;
  margin: 0px;
`;

export const styles = {
  HeaderContainer,
  Heading,
  ButtonWrapper,
  ButtonWithIcon,
  Icon,
};
