import React from "react";
import { styles } from "./styles";

type props = {
  headerText: string;
  extraText: string;
};

export default function ContentHeader({ headerText, extraText }: props) {
  return (
    <styles.HeaderContainer>
      <styles.Heading> {headerText} </styles.Heading>
      <styles.ExtraText>{extraText}</styles.ExtraText>
    </styles.HeaderContainer>
  );
}
