import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type LastUpdatedState = {
  lastUpdatedString: string;
};

// Define the initial state using that type
const initialState: LastUpdatedState = {
  lastUpdatedString: "",
};

export const lastUpdatedSlice = createSlice({
  name: "lastUpdated",
  initialState: initialState,
  reducers: {
    setInitialLastUpdatedDate: (state, action: PayloadAction<string>) => {
      state.lastUpdatedString = action.payload;
    },
  },
});

export const { setInitialLastUpdatedDate } = lastUpdatedSlice.actions;

export default lastUpdatedSlice.reducer;
