import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { color } from "../../../../../styles/colors";
import { textSize, measurments, spacer } from "../../../../../styles/variables";

const NavigationBarWrapper = styled.div`
  width: 100%;
  height: ${measurments.mmd};
  justify-content: space-between;
  align-items: center;
  display: flex;
 
  @media only screen and (max-width: 1200px) {
    flex-direction: column;
  }
  }
`;

const NavigationBar = styled.ul`
  display: flex;
  flex-direction: row;
  margin: 0px;
  padding: 0px;
  width: 100%;

  @media only screen and (max-width: 1200px) {
    margin-top: ${spacer.xl};
    display: none;
    position: absolute;
    &.visible {
      display: block;
    }
  }
`;

const ListElement = styled.li`
  flex: 1;
  list-style-type: none;
  font-size: ${textSize.normal};
  text-align: center;

  @media only screen and (max-width: 1200px) {
    padding: 5px 5px 5px 5px;
    background-color: white;
    border: 1px solid ${color.grey200};
    margin-bottom: 2px;
    margin-right: ${spacer.xxxl};
    margin-left: ${spacer.xxxl};
  }
`;

const ListElementLink = styled(NavLink)`
  text-decoration: none;
  color: black;
  font-family: Eina03-Bold;
  &:hover {
    color: ${color.red400};
  }

  &.current {
    color: ${color.red400};
  }
`;

const IconWrapper = styled.button`
  width: ${measurments.normal};
  height: auto;
  display: none;
  cursor: pointer;
  background: white;
  border: none;

  @media only screen and (max-width: 1200px) {
    display: inline;
  }
`;

const Icon = styled.img`
  padding: 0px;
  margin: 0px;
  width: ${measurments.normal};
  height: auto;
`;

export const styles = {
  NavigationBarWrapper,
  NavigationBar,
  ListElement,
  ListElementLink,
  Icon,
  IconWrapper,
};
