import styled from "styled-components";
import { spacer } from "../../../../../../styles/variables";

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  margin-right: ${spacer.sm};
`;

export const styles = {
  FilterContainer,
};
