import styled from "styled-components";
import { color } from "../../../styles/colors";
import { spacer, textSize, measurments } from "../../../styles/variables";

const SearchBarContainer = styled.div`
  position: relative;
  display: flex;

  flex-direction: row;
  background-color: white;
  border: 1px solid ${color.grey300};
  box-sizing: border-box;
  border-radius: ${spacer.xs};
  width: 100%;
  height: ${measurments.xxl};
`;

const Icon = styled.img`
  position: absolute;
  top: ${spacer.md};
  width: ${measurments.lg};
  height: auto;
  margin-left: ${spacer.md};
`;

const SearchBar = styled.input`
  border: none;
  padding: 0 0 0 ${spacer.xxl};
  width: 100%;
  font-size: ${textSize.mmd};
  color: ${color.grey400};
  font-family: Eina03-Regular;
  cursor: pointer;
`;

export const styles = {
  SearchBarContainer,
  Icon,
  SearchBar,
};
