import { useTranslation } from "react-i18next";

const useLanguage = () => {
  const { i18n } = useTranslation();
  const lang = {
    EN: i18n.language === "en",
    FI: i18n.language === "fi",
  };
  return {
    lang,
  };
};

export default useLanguage;
