import React from "react";
import { styles } from "./styles";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSelectLanguageButtonString } from "../../../../../features/languageButton/hooks/useSelectLanguageButton";
import { setInitialLanguage } from "../../../../../features/languageButton/languageButtonSlice";

export default function LanguageSelection() {
  const { i18n } = useTranslation();

  const activeButton = useSelectLanguageButtonString();
  const dispatch = useDispatch();

  const changeLanguage = (lng: string, event: any) => {
    i18n.changeLanguage(lng);
    const { name } = event.target;
    dispatch(setInitialLanguage(name));
  };

  return (
    <styles.MainContainer>
      <styles.LanguageButton
        name="finnish"
        onClick={(e) => changeLanguage("fi", e)}
        className={activeButton === "finnish" ? "activeButton" : ""}
      >
        FI
      </styles.LanguageButton>
      <styles.LanguageButton
        name="english"
        onClick={(e) => changeLanguage("en", e)}
        className={activeButton === "english" ? "activeButton" : ""}
      >
        EN
      </styles.LanguageButton>
    </styles.MainContainer>
  );
}
