import xlsx, { utils, WorkSheet } from "xlsx";
import { INewsItemType } from "../../types";

function createWorksheetFromResolvers(rows: INewsItemType[], language: string) {
  const sortedRows = rows.sort((a, b) => {
    return a.action_type > b.action_type ? 1 : -1;
  });

  let group = sortedRows.reduce((r: { [key: string]: INewsItemType[] }, a) => {
    if (a.action_type !== "") {
      r[a.action_type] = [...(r[a.action_type] || []), a];
    }
    return r;
  }, {});

  let formattedRows: Record<string, string>[] = [];

  let length = formattedRows.length;
  let ws2: xlsx.WorkSheet = {};

  const ws = utils.json_to_sheet(formattedRows);
  // const maxExcelCellSymbols = 32765;
  Object.values(group).forEach((actionTypeArray) => {
    formattedRows = actionTypeArray.map((row) => {
      let myRecord: Record<string, string> = {};

      myRecord[language === "en" ? row?.title?.en : row?.title?.fi] =
        row?.action_type;
      row.content?.forEach((element) => {
        myRecord[language === "en" ? element?.name?.en : element?.name?.fi] =
          element?.value
            ?.toString()
            // .substring(0, maxExcelCellSymbols)
            .replace(/<[^>]*>?/gm, "");
      });
      return myRecord;
    });
    ws2 = utils.sheet_add_json(
      ws,
      formattedRows, // data
      {
        // options
        skipHeader: false,
        origin: "A" + length, // start from cell A
      }
    );
    length += formattedRows.length + 4;
  });

  return ws2;
}

const downloadExcel = (workSheets: Record<string, WorkSheet>) => {
  const workBook = utils.book_new();

  workBook.SheetNames = Object.keys(workSheets);
  workBook.Sheets = workSheets;

  xlsx.writeFile(workBook, "NewsAndFundMarketActions.xlsx");
};

const exportExcel = (newsItemsList: INewsItemType[], language: string) => {
  downloadExcel({
    NewsAndFundMarketActions: createWorksheetFromResolvers(
      newsItemsList,
      language
    ),
  });
};

export default exportExcel;
