import { IFundPricesRows, INewsItemType } from "../types";

export async function getFundPricesTableRows(): Promise<IFundPricesRows> {
  /* const result = await fetch("./fund_price_table_data.json");
 const rows = result.json();
  return rows;*/

  const result = await fetch(
    "https://rahastoraportti-backend.app.sijoitustutkimus.fi/FUND_CLOSING_PRICES_JSON/Fund_ClosingPrices_LATEST.json"
  );

  const lastModified = result.headers.get("last-modified");

  // console.log(result.headers.get("last-modified"));
  const rows = await result.json();

  return { rows: rows, date: lastModified };
}

export async function getFundMarketNewsList(): Promise<INewsItemType[]> {
  //  const result = await fetch("./news_elements.json");
  const result = await fetch(
    "https://rahastoraportti-backend.app.sijoitustutkimus.fi/FUND_NEWS_ITEMS_JSON/Fund_NewsItems_LATEST.json"
  );

  const listElements = result.json();
  return listElements;
}
