import React, { ReactNode } from "react";
import { styles } from "./styles";

type props = {
  children: ReactNode;
};

export default function RightColumn({ children }: props) {
  return <styles.MainContainer>{children}</styles.MainContainer>;
}
