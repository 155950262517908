import { configureStore } from "@reduxjs/toolkit";
import fundPricesSliceReducer from "../features/fundPrices/fundPricesSlice";
import fundMarketNewsSliceReducer from "../features/fundMarketNews/fundMarketNewsSlice";
import languageButtonSliceReducer from "../features/languageButton/languageButtonSlice";
import lastUpdatedSliceReducer from "../features/lastUpdatedDate/lastUpdatedSlice";

export const store = configureStore({
  reducer: {
    fundPrices: fundPricesSliceReducer,
    fundMarketNews: fundMarketNewsSliceReducer,
    languageButton: languageButtonSliceReducer,
    lastUpdatedDate: lastUpdatedSliceReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
