import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { INewsItemType } from "../../types";

export type FundMarketNewsState = {
  fundMarketNewsList: INewsItemType[];
  searchTerm: string;
};

// Define the initial state using that type
const initialState: FundMarketNewsState = {
  fundMarketNewsList: [],
  searchTerm: "",
};

export const fundMarketNewsSlice = createSlice({
  name: "fundMarketNews",
  initialState: initialState,
  reducers: {
    setFundMarketInitialList: (
      state,
      action: PayloadAction<INewsItemType[]>
    ) => {
      state.fundMarketNewsList = action.payload;
    },
  },
});

export const { setFundMarketInitialList } =
  fundMarketNewsSlice.actions;

export default fundMarketNewsSlice.reducer;
