import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import i18n from "../../i18n";

const defaultLanguage = i18n.language === "en" ? "english" : "finnish";

export type LanguageButtonState = {
  languageString: string;
};

// Define the initial state using that type
const initialState: LanguageButtonState = {
  languageString: defaultLanguage,
};

export const languageButtonSlice = createSlice({
  name: "languageButton",
  initialState: initialState,
  reducers: {
    setInitialLanguage: (state, action: PayloadAction<string>) => {
      state.languageString = action.payload;
    },
  },
});

export const { setInitialLanguage } = languageButtonSlice.actions;

export default languageButtonSlice.reducer;
