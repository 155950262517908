import React, { ReactNode } from "react";
import { styles } from "./styles";

type Props = {
  children: ReactNode;
};

export default function FilterContainer({ children }: Props) {
  return <styles.FilterContainer>{children}</styles.FilterContainer>;
}
