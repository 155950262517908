import styled from "styled-components";
import { color } from "../../../../styles/colors";
import { textSize, measurments, spacer } from "../../../../styles/variables";

const TableContainer = styled.div`
  background-color: white;
  max-width: 100%;
  max-height: 77vh;
  padding-right: ${spacer.xl};
  padding-left: ${spacer.xl};
  overflow-y: auto;
`;

const CustomFundPricesTable = styled.table`
  width: 100%;
  th:first-child {
    text-align: left; 
}​,
  td:first-child {
  text-align: left;
}​,
`;

const CustomFundPricesTableBody = styled.tbody`
  overflow: none;
`;

const TableHeader = styled.th`
  background: ${color.grey500};
  color: ${color.grey400};
  font-size: ${textSize.xs};
  font-family: Eina03-Bold;
  cursor: pointer;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  &:hover {
    background-color: ${color.grey600};
  }
`;

const THead = styled.thead``;

const TableRow = styled.tr`
  padding: 5px;
  color: black;
  font-size: ${textSize.normal};
  font-family: Eina03-Regular;
  height: ${measurments.xxl};
  text-align: right;
  &:hover {
    background-color: ${color.grey500};
  }
`;

const TableDataCell = styled.td`
  border-bottom: 1px solid ${color.grey200};
  border-collapse: collapse;
`;
const SpanForArrow = styled.span`
  padding: 3px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: ${spacer.md};
`;

const Text = styled.p`
  display: flex;
  max-width: 100%;
`;

const Icon = styled.img`
  width: ${measurments.sm};
  height: auto;
  &.descending {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }
`;

export const styles = {
  TableContainer,
  CustomFundPricesTable,
  CustomFundPricesTableBody,
  TableHeader,
  TableRow,
  TableDataCell,
  TextWrapper,
  Text,
  SpanForArrow,
  Icon,
  THead,
};
