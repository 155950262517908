import styled from "styled-components";

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
`;

export const styles = {
  MainContainer,
};
