import styled from "styled-components";
import { color } from "../../styles/colors";

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${color.grey500};
  width: 100%;
  @media only screen and (max-width: 1200px) {
    flex-direction: column;
  }
`;

export const styles = {
  MainContainer,
};
