import React from "react";
import { ISelectBoxType } from "../../../../../../types";
import { styles } from "./styles";

type Props = {
  options: Array<Object>;
  placeholderText: string;
  onChange: Function;
  selectTerm: ISelectBoxType | null;
  clearable: boolean;
};

export default function SelectDropdown({
  options,
  placeholderText,
  onChange,
  selectTerm,
  clearable,
}: Props) {
  function handleOnChange(value: ISelectBoxType) {
    onChange(value);
  }

  return (
    <styles.SelectDropdown
      onChange={handleOnChange}
      value={selectTerm?.value ? selectTerm : null}
      options={options}
      placeholder={placeholderText}
      isClearable={clearable}
      classNamePrefix="react-select"
      theme={(theme: any) => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary25: "#C8CAD2",
          primary: "black",
        },
      })}
    />
  );
}
